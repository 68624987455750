import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Homepage } from '..'

const HomepageEs = ({ data, ...props }) => {
  return (
    <Homepage {...props} data={{ ...data, allHomeJson: data.allHomeEsJson }} />
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        banner: file(relativePath: { eq: "banner-home.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
        ctaScreenshot: allFile(
          filter: { name: { regex: "/^homepagecta*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fixed(width: 300) {
                  ...GatsbyImageSharpFixed
                  height
                  width
                }
              }
            }
          }
        }
        screenshots: allFile(
          filter: { name: { regex: "/^homepagescreenshot*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 400) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        sections: allFile(
          filter: { name: { regex: "/^homepageSection*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        quotes: allFile(
          filter: { name: { regex: "/^homepageQuote*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        testimonials: allFile(
          filter: { name: { regex: "/^homepageTestimonial*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        allHomeEsJson {
          edges {
            node {
              bannerHeadline
              heroHeading
              heroSubheading
              heroButton
              heroButtonLabel
              storeButton
              buttonLabel
              buttonLink
              buttonColor
              buttonFocus
              introImage
              imageDescription
              introMessage
              callToAction
              imageSections {
                containerColor
                urlDescription
                flip
                reverse
                button
                storeButton
                buttonLabel
                buttonColor
                buttonLink
                focus
                src
                box
                srcDescription
                titleColor
                title
                firstBody
                secondBody
                thirdBody
              }
              quoteSections {
                containerColor
                smallText
                smallTextColor
                largeText
                largeTextColor
                flip
                textAlign
                button
                storeButton
                buttonLink
                buttonLabel
                buttonBg
                buttonFocus
                backgroundAlt
              }
              firstInfoSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonColor
                buttonFocus
                buttonLabel
                buttonLink
                triangle
                bg
                order
                textAlign
                imageExists
                imageAlt
              }
              secondInfoSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonColor
                buttonFocus
                buttonLabel
                buttonLink
                triangle
                bg
                order
                textAlign
                imageExists
                imageAlt
              }
              thirdInfoSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonColor
                buttonFocus
                buttonLabel
                buttonLink
                triangle
                bg
                order
                textAlign
                imageExists
                imageAlt
              }
              testimonials {
                containerColor
                smallText
                smallTextColor
                largeText
                largeTextColor
                flip
                textAlign
                button
                storeButton
                buttonLink
                buttonLabel
                buttonBg
                buttonFocus
                backgroundAlt
              }
              pageTitle
              pageDescription
              socialTitle
              socialDescription
              socialImage
            }
          }
        }
      }
    `}
    render={data => <HomepageEs data={data} {...props} />}
  />
)
